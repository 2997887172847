<template>
  <e-chart :options="option" style="height:280px;width:100%;"></e-chart>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/markLine";
import "echarts/lib/component/graphic";
export default {
  data(){return {
    option: {
        tooltip: {
            trigger: 'item'
        },
        legend: {
             left: 'center',
        },
        series: [
            {
                type: 'pie',
                radius: '70%',
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
  }},
  props:{
    datList: {
      type: Array
    }
  },
  created: function () {
      var self=this
      //生命周期，创建完成
      console.log(self.datList)
      if(self.datList.length>0){
          self.datList.forEach((v,i)=>{
              if(i<10){
                  let datas={
                    value:v.VisitCount,
                    name:v.VisitName
                  }
                  self.option.series[0].data.push(datas)
              }
              
          })
      }
  },
  components: {//组件
      "e-chart": ECharts
  },
}
</script>

<style>

</style>