<template>
    <div id="app">
        <div id="top">招生数据排行</div>
        <div class="index-float">
            <label style="margin-left:5%">请选择时间：</label>
            <a-radio-group default-value="0" button-style="solid" style="margin-right:12px" size="small" @change="radioChange">
                <a-radio-button value="0">
                    今天
                </a-radio-button>
                <a-radio-button value="1">
                    昨天
                </a-radio-button>
                <a-radio-button value="2">
                    最近7天
                </a-radio-button>
                <a-radio-button value="3">
                    最近30天
                </a-radio-button>
            </a-radio-group>
            <a-range-picker size="small" style="width:220px" @change="handleChange" :value="Time">
                <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                        {{ current.date() }}
                    </div>
                </template>
            </a-range-picker>
        </div>
        <div class="l-tab-main">
            <a-radio-group v-model="l_tab">
                <a-radio-button value="0">
                    素材排行
                </a-radio-button>
                <a-radio-button value="1">
                    阅读排行
                </a-radio-button>
            </a-radio-group>
        </div>
        <div v-if="l_tab==0" class="l-tab-content">
            <div class="tow">
                <h2 style="font-weight:600;text-align:center;margin-top:10px;">素材排行榜</h2>
                <template>
                    <a-table  :columns="columns"
                            :data-source="DataSourceMaterial"
                            rowKey="VisitCustomerID"
                            :pagination="{ pageSize: 50 }"
                            :scroll="{ y: 650 }"
                            />
                </template>
            </div>
            <div class="l-charts">
                <h2 style="font-weight:600;text-align:center;margin-top:10px;">素材排行图表分析</h2>
                <ranking-char v-if="DataSourceMaterial.length>0"  :datList="DataSourceMaterial"></ranking-char>
            </div>
        </div>
        <div v-if="l_tab==1" class="l-tab-content">
            <div class="tow">
                <h2 style="font-weight:600;text-align:center;margin-top:10px;">海报阅读量排行榜</h2>
                <template>
                    <a-table :columns="columnd"
                            :data-source="DataPoster"
                            rowKey="VisitCustomerID"
                            :pagination="{ pageSize: 50 }"
                            :scroll="{ y: 650 }" />
                </template>
            </div>
            
            <div class="l-charts">
                <h2 style="font-weight:600;text-align:center;margin-top:10px;">海报阅读量排行图表分析</h2>
                <ranking-char v-if="DataPoster.length>0 " :datList="DataPoster"></ranking-char>
            </div>
        <!-- <div class="tow">
            <h2 style="font-weight:600;text-align:center;margin-top:10px;">素材排行榜</h2>
            <template>
                <a-table :columns="columns"
                         :data-source="DataSourceMaterial"
                         rowKey="VisitCustomerID"
                         :pagination="{ pageSize: 50 }"
                         :scroll="{ y: 650 }" />
            </template>
        </div>
        <div class="tow">
            <h2 style="font-weight:600;text-align:center;margin-top:10px;">海报阅读量排行榜</h2>
            <template>
                <a-table :columns="columnd"
                         :data-source="DataPoster"
                         rowKey="VisitCustomerID"
                         :pagination="{ pageSize: 50 }"
                         :scroll="{ y: 650 }" />
            </template>
        </div> -->
        
    </div>
    </div>
</template>
<script>
    const columns = [
        {
            title: '',
            dataindex: '',
            width: 50,
        },
        {
            title: '机构名称',
            dataIndex: 'VisitName',
            key: 'VisitName',
            width: 230,

        },
        {
            title: '素材数量',
            dataIndex: 'VisitCount',
            key: 'VisitCount',
            width: 100,
           
        },
    ];
    const columnd = [
        {
            title: '',
            dataindex: '',
            width: 50,
        },
        {
            title: '机构名称',
            dataIndex: 'VisitName',
            key: 'VisitName',
            width: 230,

        },
        {
            title: '阅读数量',
            dataIndex: 'VisitCount',
            key: 'VisitCount',
            width: 100,
        },

    ];
    import "ant-design-vue/dist/antd.css";
    import Vue from "vue";
    import ant from "ant-design-vue";
    import util from "@/Plugin/util.js";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import moment from "moment";
    import http from "@/Plugin/Http.js";
    import RankingChar from './RankingChar'
    Vue.prototype.$zhCN = zh_CN;
    Vue.use(ant);


    export default {
        name: "",
        data() {
            return {
                columns,
                columnd,
                Time: [],
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                DataSourceMaterial: [],
                DataPoster: [],
                l_tab:"0",
            };
        },

        methods: {
            
            //方法
            SourceMaterial: function () {
                var self = this;
                self.DataSourceMaterial = []
                console.log("MaterialRanking");
                console.log(self.BeginDate, self.EndDate);
                var op = {
                    url: '/OperationsModule/Ranking/MaterialRanking',
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                    },
                    OnSuccess: function (data) {
                        self.DataSourceMaterial = data.data;
                        
                        console.log("???");
                        console.log(self.DataVisit);
                    }
                };
                http.Post(op);
            },
            Poster: function () {
                var self = this;
                self.DataPoster = []
                console.log("PosterRanking");
                var op = {
                    url: '/OperationsModule/Ranking/PosterRanking',
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                    },
                    OnSuccess: function (data) {
                        self.DataPoster = data.data;
                        console.log(self.DataIntention);
                    }
                };
                http.Post(op);
            },
            radioChange: function (item) {
                var self = this;
                var Time = "";
                switch (item.target.value) {
                    case "0":
                        Time = [
                            moment(new Date(), "YYYY-MM-DD"),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "1":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                        ];
                        break;
                    case "2":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 7)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "3":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 30)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "4":
                        var year = new Date().getFullYear();
                        Time = [
                            moment(new Date(moment(year + "-01-01")), "YYYY-MM-DD"),
                            moment(new Date(moment(year + "-06-30")), "YYYY-MM-DD"),
                        ];
                        break;
                }
                self.handleChange(Time);
            },
            getCurrentStyle(current) {
                //console.log("型:");
                //console.log(today);
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                }
                return style;
            },
            handleChange(value) {
                var self = this;
                if (value.length == 0) {
                    self.BeginDate = util.formatDate(new Date());
                    self.EndDate = util.formatDate(new Date());
                    value = [
                        moment(new Date(), "YYYY-MM-DD"),
                        moment(new Date(), "YYYY-MM-DD"),

                    ];
                    self.$message.error("必须选择日期", 1.5, function () { });
                } else {
                    self.BeginDate = util.formatDate(value[0]._d);
                    self.EndDate = util.formatDate(value[1]._d);
                }
                self.Time = value;
                self.SourceMaterial();
                self.Poster();

                console.log("时间");
                console.log(self.BeginDate, self.EndDate);
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
           l_tab(o,v){
               var self=this
               console.log(o,v)
                if(o==1){
                    self.DataPoster=[]
                    self.Poster();
                    
                }else{
                    self.DataSourceMaterial=[]
                    self.SourceMaterial();
                }
            }
        },
        components: {//组件
            "ranking-char":RankingChar
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            self.Time = [
                moment(new Date(), "YYYY-MM-DD"),
                moment(new Date(), "YYYY-MM-DD"),
            ];
            self.SourceMaterial();

        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }
</script>
<style>
    #app {
        background-color: #F0F2f5;
    }

    #top {
        text-align: center;
        color: #186ee5;
        font-size: 2.5em;
        font-weight: 600;
    }

    。
    .index-float {
        margin-left: 200px;
    }
    .l-tab-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .l-charts{
        width: 48%;
        height: 840px;
        background-color: #ffffff;
        margin-top: 20px;
        margin-right: 2%;
        padding:10px;
    }
    .l-charts-bar{
        width: 100%;
        min-width: 100%;
    }
    .tow {
        width: 44%;
        height: 840px;
        /* float: left; */
        background-color: #ffffff;
        margin-top: 20px;
        margin-left: 4%;
        padding:10px;
    }
    ::-webkit-scrollbar { /*滚动条整体样式*/
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }

    ::-webkit-scrollbar-thumb { /*滚动条里面小方块*/

        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: #c5c5c5;
    }

    ::-webkit-scrollbar-track { /*滚动条里面轨道*/

        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 10px;
        background: #f2f2f9;
    }
    .l-tab-main{
        margin-left: 5%;
        margin-top:5px;
    }
    .ant-table-fixed-header .ant-table-scroll .ant-table-header{
        overflow: auto !important;
    }
</style>
